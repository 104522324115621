import { all, call, put, select, takeEvery, race, take } from 'redux-saga/effects';

import book from 'routes/book';

import { accountingActions as actions, accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';
import { createUi } from 'bus/ui/helpers.js';
import { registerUI, updateUI } from 'bus/ui/actions.js';
import { bookingActions } from 'bus/booking/actions';

import { sendInvoicesList } from 'api/methods/accounting';

import { showInvoiceModal } from 'components/modals/InvoiceModal';

import {
  RESULT_AKTS_DEMO_TYPE,
  RESULT_INVOICES_TYPE,
  SEND_INVOICES_UI_KEY
} from '../../constants.js';

import {
  reExposePayments,
  sendInvoicesList as sendInvoicesListAction,
  sendActs as sendActsAction
} from './actions';

function* reExposePaymentsWorker({ payload: { invoicesBaseId, invoice, bookingId } }) {
  yield put(showInvoiceModal({
    props: {
      invoice,
      meta: bookingId ? { booking: bookingId } : {}
    },
    mapActions2Store: {
      onSuccess: dispatch => {
        dispatch(bookingActions.getBookingInvoices(bookingId));
      },
    },
    onClose: dispatch => {
      dispatch(actions.clearOutboxInvoiceBase());
    }
  }));

  yield put(accountingActions.getOutboxInvoiceBase(invoicesBaseId));
}

function* sendInvoicesWorker({ payload: invoices }) {
  const token = yield select(getToken);

  yield put(registerUI(createUi({ loading: true }), SEND_INVOICES_UI_KEY));

  try {
    const results = yield call(sendInvoicesList, token, { bodyParams: { invoices } });

    yield put(accountingActions.setInvoiceSendingModalValues({ results, type: RESULT_INVOICES_TYPE }));
  } catch (error) {
    yield put(updateUI({ message: 'Ошибка сервера', error: true }, SEND_INVOICES_UI_KEY));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, SEND_INVOICES_UI_KEY));
  }
}

function* sendActsWorker({ payload: { invoices, demo = true, date } }) {
  yield put(accountingActions.sendAkts(invoices, demo, date));

  const [sendAktsSuccess] = yield race([
    take(accountingActions.sendAktsSuccess),
    take(accountingActions.sendAktsFail),
  ]);

  if (sendAktsSuccess) {
    if (demo) {
      yield put(accountingActions.setInvoiceSendingModalValues({
        results: sendAktsSuccess.payload,
        type: RESULT_AKTS_DEMO_TYPE
      }));
    } else {
      window.open(book.adminAccounting.children.akt, '_self');
    }
  }
}

export default function* () {
  yield all([
    takeEvery(reExposePayments, reExposePaymentsWorker),
    takeEvery(sendInvoicesListAction, sendInvoicesWorker),
    takeEvery(sendActsAction, sendActsWorker),
  ]);
}
