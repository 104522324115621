// instruments
import BaseApiClass from 'api/BaseApiClass';

export const getBookingSchedules = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/schedules',
  method: 'GET',
  ...params,
})._load({ token });

export const createBookingSchedule = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/schedules',
  method: 'POST',
  ...params,
})._load({ token });

export const updateBookingSchedule = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/schedules/{:scheduleId}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteBookingSchedule = (token, params = {}) => new BaseApiClass({
  url: 'bookings/{:id}/schedules/{:scheduleId}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });
