import { handleActions, combineActions } from 'redux-actions';
import { Map } from 'immutable';

// actions
import { subscriptionActions as actions } from 'bus/subscription';

const initialState = Map({
  plans: {
    active: null,
    last: null,
    defaults: null,
    types: null,
  },
  balances: {
    store: [],
    amount: [],
    invoices: [],
  },
  subscription: null,
  charge: null,
  balanceInfo: null,
});

export const subscriptionReducer = handleActions({
  [actions.getSubscriptionSuccess]: (state, { payload }) => {
    const { activePlan, lastPlan, defaultPlans, subscriptionTypes, currency } = payload;

    return state
      .setIn(['plans', 'active'], activePlan)
      .setIn(['plans', 'last'], lastPlan)
      .setIn(['plans', 'defaults'], defaultPlans)
      .setIn(['plans', 'types'], subscriptionTypes)
      .setIn(['plans', 'currency'], currency);
  },
  [actions.resetSubscriptionPlans]: state => {
    return state
      .setIn(['plans', 'active'], null)
      .setIn(['plans', 'last'], null)
      .setIn(['plans', 'defaults'], null)
      .setIn(['plans', 'types'], null);
  },
  [combineActions(
    actions.getBalancesSuccess,
    actions.resetBalances
  )]: (state, { payload: balances }) => {
    return state.setIn(['balances', 'store'], balances);
  },
  [combineActions(
    actions.getBalanceAmountSuccess,
    actions.resetBalanceAmount
  )]: (state, { payload: amount }) => {
    return state.setIn(['balances', 'amount'], amount);
  },
  [combineActions(
    actions.getBalanceInvoicesSuccess,
    actions.resetBalanceInvoices
  )]: (state, { payload: invoices }) => {
    return state.setIn(['balances', 'invoices'], invoices);
  },
  [combineActions(
    actions.createSubscriptionSuccess,
    actions.resetSubscription
  )]: (state, { payload: subscription }) => {
    return state.set('subscription', subscription);
  },
  [combineActions(
    actions.createBalanceChargeSuccess,
    actions.resetBalanceCharge
  )]: (state, { payload: charge }) => {
    return state.set('charge', charge);
  },
  [combineActions(
    actions.getBalanceInfoSuccess,
    actions.resetBalanceInfo
  )]: (state, { payload: balance }) => {
    return state.set('balanceInfo', balance);
  },
}, initialState);
