import { call, select, all } from 'redux-saga/effects';
import * as R from 'ramda';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import { newInstance } from 'localization';

import book from 'routes/book';

import { createBooking, updateBooking } from 'api/methods/booking';
import { createBookingTourist } from 'api/methods/booking/tourists';
import { updateBookingCash } from 'api/methods/booking/money';

import { NEW_MONEY_TYPES } from 'containers/form/booking/constants';

import { getToken, getUserTfFirstCounterpartyID } from 'bus/auth/selectors';
import { getOperatorOptions, getOperators } from 'bus/common/selectors';

import {
  createCommentForBookingTicket,
  getDefaultOptions,
  isSetViolations,
  mapValuesToApi,
  getTicketPrice,
  getBookingFromTicket
} from './heplers';

const ON_BOOKING_STATUS = 7;

export function* bookingTicketByOperatorWorker({ payload }) {
  const { bookingEntity, callbacks, ticket } = payload;
  const { onSuccess, onFail } = callbacks;

  const token = yield select(getToken);
  const operatorID = yield select(state => {
    const otpuskId = R.or(
      ticket.to.source?.otpuskId,
      R.or(
        ticket.from.source?.otpuskId,
        ticket.source?.otpuskId
      )
    );

    return R.call(
      R.pipe(
        getOperators,
        R.find(operator => R.or(
          operator.otpusk_id === otpuskId,
          R.includes(String(otpuskId), operator.alternative_otpusk_ids ?? [])
        )),
        R.prop('id')
      ),
      state
    );
  });

  const firstCounterpartyId = yield select(getUserTfFirstCounterpartyID);

  const bookingEntityForAPI = yield select(state => mapValuesToApi(
    bookingEntity,
    getOperatorOptions(state, { id: operatorID })
  ));

  try {
    const booking = yield call(createBooking, token, {
      bodyParams: {
        operator: `${operatorID}`,
        status: ON_BOOKING_STATUS,
      },
    });

    yield all([
      call(updateBooking, token, {
        pathParams: { id: booking.id },
        bodyParams: {
          comment: createCommentForBookingTicket(ticket, bookingEntityForAPI),
          counterparty_tf: firstCounterpartyId,
          country: R.or(
            ticket.to.arrivalCountry?.otpuskId,
            ticket.from.arrivalCountry?.otpuskId
          ),
          check_in: moment(R.or(
            ticket.to.departureDatetimeTo,
            ticket.from.departureDatetimeFrom
          ))
            .utc(false)
            .format('YYYY-MM-DD'),
          check_out: R.call(
            R.pipe(
              R.ifElse(
                ({ from }) => from.departureDatetimeFrom,
                ({ from }) => from.departureDatetimeFrom,
                ({ to }) => to.arrivalDatetimeTo
              ),
              checkOut => moment(checkOut).utc(false).format('YYYY-MM-DD')
            ),
            ticket
          ),
          operator_option_ids: getDefaultOptions(bookingEntityForAPI.options),
          booking_type: getBookingFromTicket(
            ticket.to?.transport ? ticket.to : ticket.from
          )
        },
      }),
      call(updateBookingCash, token, {
        pathParams: { id: booking.id },
        bodyParams: {
          [NEW_MONEY_TYPES.CURRENCY]: ticket.currency,
          [NEW_MONEY_TYPES.SOURCE_PRICE]: getTicketPrice(ticket)
        },
      }),
      call(createBookingTourist, token, {
        pathParams: { id: booking.id },
        bodyParams: { tourists: bookingEntityForAPI.passports },
      })
    ]);

    if (onSuccess) {
      onSuccess({
        id: `T-${booking.id}`,
        viewLink: generatePath(book.client.children.bookingPage, { id: booking.id }),
        bookingsLink: book.client.children.booking,
      });
    }
  } catch (error) {
    const { body, message } = error.msg || { message: error.message };

    const errorMessageList = [];

    if (isSetViolations(body)) {
      errorMessageList.push(
        ...R.map(
          R.prop('message'),
          R.path(['validation', 'violations'], body)
        )
      );
    }

    if (message) {
      errorMessageList.push(message);
    }

    if (R.isEmpty(errorMessageList)) {
      errorMessageList.push(newInstance.t('ERRORS:ERROR_LOAD_DATA'));
    }

    onFail && onFail(new Error(R.join(', ', errorMessageList)));

    if (onFail) {
      onFail(error);
    }
  }
}
