import { select, put, call, delay, take, race } from 'redux-saga/effects';
import { get, getIn } from 'immutable';

import { registerUI, updateUI } from 'bus/ui/actions';
import { filesActions } from 'bus/files/actions';
import { getBookingsCount, getBookingSearchPage, getQuery } from 'bus/booking/selectors';
import { createUi } from 'bus/ui/helpers';

import { createBookingJob } from 'api/methods/files';
import { MyError } from 'api/fn/Error';
import { prepareDataForApi } from 'api/methods/booking/helpers';

import { newInstance } from 'localization';

const BASE_UI_PATH = ['files', 'bookingJob'];

const DELAY = 5 * 1000; // 5 seconds
const MAX_BOOKINGS_COUNT = 10000;

export function* createBookingJobWorker() {
  const token = yield select(({ auth }) => auth.get('token'));

  const values = yield select(getQuery);
  const page = yield select(getBookingSearchPage);
  const bookingsCount = yield select(getBookingsCount);

  const params = prepareDataForApi(values);
  try {
    yield put(registerUI(createUi({ loading: true }), BASE_UI_PATH));

    if (bookingsCount > MAX_BOOKINGS_COUNT) {
      throw new MyError({
        status: 403,
        body: { message: newInstance.t('BOOKING_ADMIN_PAGE:EXPORT_ERROR') }
      });
    }

    const { id } = yield call(createBookingJob, token, { bodyParams: {
      page,
      ...params,
    } });

    while (true) {
      yield delay(DELAY);

      yield put(filesActions.getStatusJobs(id));

      const [successJobs, failJobs] = yield race([
        take(filesActions.getStatusJobsSuccess),
        take(filesActions.getStatusJobsFail)
      ]);
      const successState = getIn(successJobs, ['payload', 'state']);

      if (successState === 'success') {
        break;
      }
      if (successState === 'fail') {
        throw new MyError({ status: 500 });
      }
      if (failJobs) {
        throw failJobs.payload;
      }
    }

    yield put(filesActions.createBookingJobSuccess(id));
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    switch (status) {
      case 404: {
        message = 'Недопустимый формат';

        break;
      }
      case 403: {
        message = get(body, 'message', 'Необходимо иметь разрешение на доступ к разделу "Клиенты". Запрещено если активна подписка только на Турсканер');

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(updateUI({ message, error: true }, BASE_UI_PATH));
  } finally {
    yield put(updateUI({ completed: true, loading: false }, BASE_UI_PATH));
  }
}
