import { call, put, race, take } from 'redux-saga/effects';
import { authActions as actions } from 'bus/auth/actions';

import { logining } from 'api/methods/auth/user';
import { setGroupValue, GROUPS } from 'helpers/storage';

export function* autheticateWorker({ payload }) {
  const { values, remember, locale, formik } = payload;

  try {
    const { token } = yield call(logining, { token: null, locale }, { bodyParams: values });

    yield call(setGroupValue, GROUPS.auth, { token, remember });
    yield put(actions.authenticateSuccess(token));
    yield put(actions.fetchMyself());
    yield race([
      take(actions.fetchMyselfSuccess),
      take(actions.logOut)
    ]);
  } catch (error) {
    const { status, body } = error.msg;

    let message = null;

    switch (status) {
      case 401: {
        break;
      }
      case 403: {
        message = body.message || 'Отказано в доступе';
        break;
      }
      default: message = 'Ошибка сервера';
    }

    formik && message && (yield call(formik.setStatus, { error: true, message }));
    formik && (yield call(formik.setSubmitting, false));
  }
}
