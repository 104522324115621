import { mixed } from 'yup';

import { formatingSum } from 'helpers/inputs';

import { newInstance } from '../../../localization';

export const stringToNumber = (str, resultCreator = v => v) => {
  try {
    if (typeof str === 'number') {
      return resultCreator(str, 'success');
    }

    if (str === '') {
      throw new Error('Handle empty field');
    }

    const normalizers = [
      v => v.replace(',', '.'),
      v => v
        .split('')
        .filter(part => part.trim())
        .join(''),
      Number
    ];

    const normalizedValue = normalizers.reduce((v, func) => func(v), str);

    return resultCreator(normalizedValue, 'success');
  } catch (error) {
    return resultCreator(str, 'error');
  }
};

export const numberToString = (number, formatingOptions) => {
  const normalizers = [
    v => (Number.isNaN(v) ? v : Number(v)),
    v => (typeof v !== 'number' ? '' : v),
    v => formatingSum(v, formatingOptions)
  ];

  return normalizers.reduce((v, func) => func(v), number);
};

export const createNumberValidation = (fieldName, config) => {
  const { required, min = '', max = '', maxMessage, minMassage } = config;

  return mixed()
    .default('')
    .transform((_, originalValue) => stringToNumber(originalValue))
    .test(
      fieldName,
      newInstance.t('FILTERS:REQ'),
      val => {
        if (required && val) {
          return Boolean(
            String(val).trim().length
          );
        }

        return true;
      }
    )
    .test(
      fieldName,
      newInstance.t('VALIDATION:ONLY_PURE_NUMBERS'),
      val => {
        const enableTest = val !== '';

        if (enableTest) {
          return typeof val === 'number' && Number.isFinite(val);
        }

        return true;
      }
    )
    .test(
      fieldName,
      newInstance.t(minMassage ? minMassage : 'VALIDATION:SYMBOLS', { length: min }),
      val => {
        const enableTest = val !== '';

        if (enableTest && typeof min === 'number') {
          return val >= min;
        }

        return true;
      }
    )
    .test(
      fieldName,
      newInstance.t(maxMessage ? maxMessage : 'VALIDATION:SYMBOLS_MAX', { length: max }),
      val => {
        const enableTest = val !== '';

        if (enableTest && typeof max === 'number') {
          return val <= max;
        }

        return true;
      }
    );
};
