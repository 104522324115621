import * as R from 'ramda';

export const UAH_CURRENCY_OTPUSK = {
  code: 'uah',
  label: 'грн',
  symbol: '₴',
};
export const UAH_CURRENCY = {
  code: 'hrn',
  label: 'грн',
  symbol: '₴',
};
export const USD_CURRENCY = {
  code: 'usd',
  label: '$',
  symbol: '$',
};
export const EUR_CURRENCY = {
  code: 'eur',
  label: '€',
  symbol: '€',
};
export const RUB_CURRENCY = {
  code: 'rub',
  label: 'руб',
  symbol: 'р',
};
export const KZT_CURRENCY = {
  code: 'kzt',
  label: 'тенге',
  symbol: '₸',
};
export const BYN_CURRENCY = {
  code: 'byn',
  label: 'руб',
  symbol: 'р',
};
export const AZN_CURRENCY = {
  code: 'azn',
  label: 'ман',
  symbol: '₼',
};
export const USZ_CURRENCY = {
  code: 'uzs',
  label: 'Сўм',
  symbol: 'С',
};
export const TMT_CURRENCY = {
  code: 'tmt',
  label: 'ман',
  symbol: 'm',
};

export const TJS_CURRENCY = {
  code: 'tjs',
  label: 'сом',
  symbol: 'с',
};
export const KGS_CURRENCY = {
  code: 'kgs',
  label: 'сом',
  symbol: 'с',
};
export const MDL_CURRENCY = {
  code: 'mdl',
  label: 'лей',
  symbol: 'лей',
};

export const PLN_CURRENCY = {
  code: 'pln',
  label: 'złoty',
  symbol: 'zł',
};
export const BNG_CURRENCY = {
  code: 'bgn',
  label: 'лв',
  symbol: 'лв',
};
export const CZK_CURRENCY = {
  code: 'czk',
  label: 'Kč',
  symbol: 'Kč',
};

export const CURRENCIES = [
  UAH_CURRENCY, UAH_CURRENCY_OTPUSK, USD_CURRENCY, EUR_CURRENCY, RUB_CURRENCY, KZT_CURRENCY, BYN_CURRENCY, AZN_CURRENCY,
  USZ_CURRENCY, TMT_CURRENCY, TJS_CURRENCY, KGS_CURRENCY, MDL_CURRENCY, PLN_CURRENCY, BNG_CURRENCY, CZK_CURRENCY
];

export const CURRENCIES_MAP = R.fromPairs(
  R.map(
    currency => [currency.code, currency],
    CURRENCIES
  )
);
