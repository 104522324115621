/* eslint-disable */
import { race, take, put, call } from 'redux-saga/effects';
import { getIn, Map } from 'immutable';

import { commonActions } from 'bus/common/actions';

import { newInstance } from 'localization';

const parseErrors = error => {
  const { status, body } = error.msg;

  switch (status) {
    case 400: {
      const violations = getIn(body, ['violations'], []);

      if (violations.length) {
        return { fields: Map({ ...violations }).mapEntries(([, value]) => [value.property_path, value.message]).toObject() };
      }

      return 'Ошибка при создании реквизита';
    }
    default: return 'Ошибка при создании реквизита';
  }
};

export function* createDetailWorker({ payload }) {
  const { tempID, detail, file, formik } = payload;

  yield put(commonActions.createDetail(tempID, detail, { UI: false }));

  let [success, fail] = yield race([
    take(commonActions.createDetailSuccess),
    take(commonActions.createDetailFail)
  ]);

  if (file && success) {
    yield put(commonActions.uploadDetailStamp(success.payload.detail.id, file));

    const response = yield race([
      take(commonActions.uploadDetailStampSuccess),
      take(commonActions.uploadDetailStampFail)
    ]);

    success = response[0];
    fail = response[1];
  }

  if (success) {
    yield call(formik.resetForm);
    yield call(formik.setStatus, { success: true, message: newInstance.t('SUCCESS_MESSAGES:DETAILS_SUCCESSFULLY') });
  }

  if (fail) {
    const errors = parseErrors(fail.payload);

    if (typeof errors === 'string') {
      yield call(formik.setStatus, { error: true, message: errors });
    } else if (typeof errors === 'object') {
      yield call(formik.setErrors, errors);
    }
  }

  yield call(formik.setSubmitting, false);
}

export function* updateDetailWorker({ payload }) {
  const { id, detail, file, formik } = payload;

  yield put(commonActions.updateDetail(id, detail, { UI: false }));

  let [successUpdate, failUpdate] = yield race([
    take(commonActions.updateDetailSuccess),
    take(commonActions.updateDetailFail)
  ]);

  if (file && successUpdate) {
    yield put(commonActions.uploadDetailStamp(id, file));

    const response = yield race([
      take(commonActions.uploadDetailStampSuccess),
      take(commonActions.uploadDetailStampFail)
    ]);

    successUpdate = response[0];
    failUpdate = response[1];
  }

  if (successUpdate) {
    yield call(formik.setStatus, { success: true, message: newInstance.t('GLOBAL:SAVE_SUCCESS') });
  }

  if (failUpdate) {
    const errors = parseErrors(failUpdate.payload);

    if (typeof errors === 'string') {
      yield call(formik.setStatus, { error: true, message: errors });
    } else if (typeof errors === 'object') {
      yield call(formik.setErrors, errors);
    }
  }

  yield call(formik.setSubmitting, false);
}
