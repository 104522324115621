import * as R from 'ramda';

import { createArrayValue, createBoolValue, createMomentValue } from 'services/formCreator/helpers';

export const CATEGORIES_MAP = {
  BOOKINGS: 'bookings',
  MESSAGES: 'messages',
};

export const DEFAULT_VALUES = {
  operators: [],
  op_claim_id: '',
  organizations: [],
  booking_id: '',
  filled_type: null,
  booking_type: null,

  statuses: [],
  payment_to_operator_status: [],
  payment_to_central_office_status: [],
  processing: [],
  countries: [],
  tourist: '',
  created_from: null,
  created_to: null,
  commission_groups: [],

  check_in_from: null,
  check_in_to: null,
  check_out_from: null,
  check_out_to: null,
  curator: 0,
  curator_tf: 0,
  calculation_type: [],
  counterparties: [],
  tf_groups: [],
  has_new_messages: null,
  is_doc_issued: null,

  categories: CATEGORIES_MAP.BOOKINGS,
};
export const INITIAL_FORM_VALUES = {
  ...R.clone(DEFAULT_VALUES),
  calculation_type: [0, 1, 3, 4],
  processing: [0],
};

export const dataFields = ['created_from', 'created_to', 'check_in_from', 'check_in_to', 'check_out_from', 'check_out_to'];
const arrayValues = [
  'operators', 'organizations', 'statuses', 'calculation_type',
  'processing', 'commission_groups', 'tf_groups', 'payment_to_operator_status',
  'payment_to_central_office_status', 'countries', 'counterparties'
];
export const MAPPED_OPTIONS = [
  [(_, key) => R.includes(key, arrayValues), createArrayValue],
  [(_, key) => R.includes(key, dataFields), createMomentValue],
  [(_, key) => R.equals('filled_type', key), Number],
  [(_, key) => R.equals('booking_type', key), Number],
  [(_, key) => R.equals('is_doc_issued', key), createBoolValue],
  [(_, key) => key === 'curator' || key === 'curator_tf', value => (value === 'null' ? 'none' : Number(value))],
];
