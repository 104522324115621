import * as R from 'ramda';

import { CRM_TYPES } from 'crms/constants';

export const getCrmTypes = () => ({
  isMt: __CRM__ === CRM_TYPES.mt,
  isTat: __CRM__ === CRM_TYPES.tat,
  is5Stars: __CRM__ === CRM_TYPES['5stars'],
  isEasybooking: __CRM__ === CRM_TYPES.easybooking,
  isNakanikuly: __CRM__ === CRM_TYPES.nakanikuly,
});

export const getCrmsPermission = crmsList => R.ifElse(
  R.length,
  R.any(type => __CRM__ === type),
  R.T
)(crmsList);

export const CRMPermissionProvider = ({ children, oneOf: allowedCrms }) => {
  const isRender = getCrmsPermission(allowedCrms);

  return isRender && children;
};
