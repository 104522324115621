import { put, call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { accountingActions as actions } from 'bus/accounting/actions';
import { withUIWorker } from 'bus/ui/helpers';
import { getToken } from 'bus/auth/selectors';

import { getCounterpartiesLink } from 'api/methods/accounting';
import { getErrorMessage } from 'api/fn/parseErrors';

import { COUNTERPARTIES_LINK_PATH } from '../../constants';

// TODO_ERROR
function* getCounterpartiesLinkWorker({ payload: id }) {
  const token = yield select(getToken);

  try {
    const data = yield call(getCounterpartiesLink, token, { pathParams: { id } });

    yield put(actions.getCounterpartiesLinkSuccess(R.prop('link', data)));
  } catch (error) {
    const message = getErrorMessage(
      error,
      {
        403: 'Отказано в доступе',
        404: 'Контрагент не найден',
      }
    );

    error.message = message ? message : 'Ошибка сервера';

    yield put(actions.getCounterpartiesLinkFail(error));
  }
}

export default withUIWorker(
  [actions.getCounterpartiesLinkSuccess, actions.getCounterpartiesLinkFail],
  COUNTERPARTIES_LINK_PATH,
)(getCounterpartiesLinkWorker);
