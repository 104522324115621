import React, { useMemo, memo, useCallback, isValidElement } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import { get } from 'immutable';

// instruments
import { uiActions } from 'bus/ui/actions';
import book from 'routes/book';

export const SUBSCRIPTION_IS_OVER = null;

export const withNotifySubscription = () => Component => memo(props => {
  const subscription = useSelector(({ auth }) => auth.getIn(['profile', 'tf', 'subscription']));
  const ui = useSelector(state => state.ui.getIn(['subscription', 'notifyByEnding']));

  // methods
  const dispatch = useDispatch();
  const showNotify = useCallback(() => {
    dispatch(uiActions.changeUiLoaderFlag({ status: true, path: ['subscription', 'notifyByEnding', 'visible'] }));
  }, []);
  const closeNotify = useCallback(() => {
    dispatch(uiActions.changeUiLoaderFlag({ status: false, path: ['subscription', 'notifyByEnding', 'visible'] }));
  }, []);

  // computed props
  const subscriptionNotify = useMemo(() => ({
    subscriptionIsOver: subscription === SUBSCRIPTION_IS_OVER,
    showNotify,
    closeNotify,
    ui,
  }), [subscription, showNotify, closeNotify, ui]);

  return <Component subscriptionNotify={subscriptionNotify} {...props} />;
});

const subscriptionSelector = ({ auth }) => auth.getIn(['profile', 'tf', 'subscription']);

export const useSubscription = ({ checkAccess, nullable: allowSubscriptionNullable = true }) => {
  const subscription = useSelector(subscriptionSelector, shallowEqual);

  const isHaveAccess = useMemo(() => {
    if (subscription === SUBSCRIPTION_IS_OVER && allowSubscriptionNullable) {
      return true;
    }

    if (subscription) {
      return checkAccess(subscription);
    }

    return false;
  }, [subscription, checkAccess, allowSubscriptionNullable]);

  return isHaveAccess;
};

export const subscriptionAccess = ({ tourscanner }) => tourscanner > 0;

export const useSubscriptionWithStatuses = ({ checkAccess, nullable }) => {
  const access = useSubscription({ checkAccess, nullable });
  const subscription = useSelector(subscriptionSelector, shallowEqual);
  const subscriptionStatuses = useMemo(() => {
    const tourscanner = get(subscription, 'tourscanner', SUBSCRIPTION_IS_OVER);

    return {
      IS_ONLY_CRM: tourscanner === 0,
      IS_FULL: tourscanner === 1,
      IS_ONLY_TS: tourscanner === 2,
      IS_OVER: tourscanner === SUBSCRIPTION_IS_OVER,
    };
  }, [subscription]);

  return { access, subscription, subscriptionStatuses };
};

export const isTfSubscription = ({ tourscanner }) => tourscanner > 0;
export const getIsFullSubscription = ({ tourscanner }) => tourscanner === 1;

export const SubscriptionProvider = memo(props => {
  const { checkAccess, children, nullable = true } = props;
  const { access: isHaveAccess, subscription, subscriptionStatuses } = useSubscriptionWithStatuses({ checkAccess, nullable });

  return isValidElement(children) ? isHaveAccess && children : children(isHaveAccess, subscription, subscriptionStatuses);
});

SubscriptionProvider.displayName = 'SubscriptionProvider';
SubscriptionProvider.propTypes = {
  checkAccess: propTypes.func.isRequired,
  children: propTypes.oneOfType([propTypes.element, propTypes.func]).isRequired,
  nullable: propTypes.bool,
};

export const SubscriptionProviderPage = memo(({ children, ...props }) => {
  return (
    <SubscriptionProvider {...props}>
      { (...[access, , statuses]) => {
        const { IS_ONLY_TS } = statuses;

        if (access) {
          return children;
        }

        if (!access && IS_ONLY_TS) {
          return <Redirect to={book.tourscanner.path} />;
        }

        return children;
      } }
    </SubscriptionProvider>
  );
});
SubscriptionProviderPage.displayName = 'SubscriptionProviderPage';
SubscriptionProviderPage.propTypes = {
  children: propTypes.element.isRequired,
};
