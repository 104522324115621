import book from 'routes/book';
import { generatePath } from 'react-router-dom';

import { CRM_TYPES } from 'crms/constants';

import { useCountClaims, useGetMessagesCount } from './hooks';

export const PAGES = {
  TS: {
    text: __CRM__ === CRM_TYPES.nakanikuly ? 'TS_NK' : 'TS',
    url: book.tourscanner.path,
    color: 'pink',
    permissions: { required: ['TOURSCANNER'] },
    subscription: ({ tourscanner }) => tourscanner > 0,
    allowSubscriptionNullable: false,
  },
  DEALS: {
    text: 'DEALS',
    url: book.main,
    exact: true,
    color: 'green',
    subscription: ({ tourscanner }) => tourscanner < 2,
    labelCreator: useCountClaims,
  },
  CLIENTS: {
    text: __CRM__ === CRM_TYPES.nakanikuly ? 'CLIENTS_NK' : 'CLIENTS',
    url: book.client.path,
    color: 'yellow',
    permissions: { required: ['CLAIMS'] },
    subscription: ({ tourscanner }) => tourscanner < 2,
    labelCreator: useGetMessagesCount,
    labelLink: book.client.children.booking,
    pathToRedirect: __CRM__ !== CRM_TYPES.mt && book.client.children.booking,
  },
  PAYMENTS: {
    text: 'PAYMENTS',
    url: book.fin.path,
    color: 'blue',
    subscription: ({ tourscanner }) => tourscanner < 2,
  },
  REPORTS: {
    text: 'REPORTS',
    url: book.stat.path,
    color: 'purple',
    permissions: { oneOf: ['CLAIM_STAT_OWN', 'CLAIM_STAT_ALL'] },
    subscription: ({ tourscanner }) => tourscanner < 2,
  },
  MAILING_LISTS: {
    text: 'MAILING_LISTS',
    url: book.marketing.path,
    color: 'violet',
    permissions: { required: ['MAILING'] },
    subscription: ({ tourscanner }) => tourscanner < 2,
  },
  COMMISSION: {
    text: 'COMMISSION',
    url: book.commission,
    color: 'purple',
    subscription: ({ tourscanner }) => tourscanner < 2,

  },
  COMMISSION_5Start: {
    text: 'COMMISSION',
    color: 'violet',
    url: generatePath(book.news.children.single, { id: 377 }),
    subscription: ({ tourscanner }) => tourscanner < 2,
  },
  NEWS: {
    text: 'NEWS',
    color: 'violet',
    url: book.news.path,
    subscription: ({ tourscanner }) => tourscanner < 2,
  },
  DIRECTORY: {
    text: 'DIRECTORY',
    url: `${book.news.path}?id=3`,
    color: 'violet',
    subscription: ({ tourscanner }) => tourscanner < 2,
    isNeedCompareSearchPath: true,
    activeSearchStr: '?id=3',
  },
  SERVICES: {
    text: 'SERVICES',
    url: `${book.news.path}?id=1`,
    color: 'violet',
    subscription: ({ tourscanner }) => tourscanner < 2,
    isNeedCompareSearchPath: true,
    activeSearchStr: '?id=1',
  },
  EXCHANGE: {
    exact: true,
    text: 'EXCHANGE',
    url: 'https://birzha.5stars.kz/birzha',
    color: 'violet',
    subscription: ({ tourscanner }) => tourscanner < 2,
    externalSource: true,
  },
};

const defaultPages = {
  mainPages: [
    PAGES.TS,
    PAGES.DEALS,
    PAGES.CLIENTS,
    PAGES.REPORTS,
    PAGES.MAILING_LISTS
  ],
};

export const PAGES_FROM_CRM = {
  [CRM_TYPES.mt]: {
    mainPages: [
      PAGES.TS,
      PAGES.DEALS,
      PAGES.CLIENTS,
      PAGES.PAYMENTS,
      PAGES.REPORTS,
      PAGES.MAILING_LISTS
    ],
  },
  [CRM_TYPES['5stars']]: {
    mainPages: [
      PAGES.TS,
      PAGES.DEALS,
      PAGES.CLIENTS,
      PAGES.REPORTS,
      PAGES.COMMISSION_5Start,
      PAGES.EXCHANGE
    ],
    additionPages: [
      PAGES.SERVICES,
      PAGES.MAILING_LISTS,
      PAGES.DIRECTORY
    ],
  },
  [CRM_TYPES.easybooking]: {
    mainPages: [
      PAGES.TS,
      PAGES.DEALS,
      PAGES.CLIENTS,
      PAGES.REPORTS,
      PAGES.COMMISSION,
      PAGES.MAILING_LISTS
    ]
  },
  [CRM_TYPES.nakanikuly]: {
    mainPages: [
      PAGES.TS,
      PAGES.DEALS,
      PAGES.CLIENTS,
      PAGES.COMMISSION,
      PAGES.NEWS
    ]
  },
};

export const getPagesForCRM = () => PAGES_FROM_CRM[__CRM__] || defaultPages;
