import { compose } from 'redux';
import * as R from 'ramda';

import BaseApiClass from 'api/BaseApiClass';

import { removeNull, removeEmptyString, removeSpaces } from 'helpers';
import { fixedValueAndConvertToNumber } from 'helpers/inputs';

import { createQueryFromApi } from './helpers';

export const searchAccountingPayments = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'accounting/payments',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = body => {
    return {
      ...compose(removeSpaces, removeNull, removeEmptyString, createQueryFromApi)(body),
    };
  };

  return instance._load({ token });
};

export const searchAccountingAkt = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'accounting/akt',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = body => {
    return {
      ...compose(removeSpaces, removeNull, removeEmptyString, createQueryFromApi)(body),
    };
  };

  instance.parseBodyToClientSchema = ({ results, ...rest }) => ({
    ...rest,
    results: R.map(({
      counterparty_text: counterpartyText,
      organization_text: organizationText,
      is_deleted: isDeleted,
      akt_dispatches: aktDispatches,
      akt_date: aktDate,
      ...other
    }) => ({
      ...other,
      counterpartyText: JSON.parse(counterpartyText),
      organizationText: JSON.parse(organizationText),
      isDeleted,
      aktDispatches,
      aktDate,
    }), results),
  });

  return instance._load({ token });
};

export const searchAccountingInvoices = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'accounting/invoices',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = body => {
    return {
      ...compose(removeSpaces, removeNull, removeEmptyString, createQueryFromApi)(body),
    };
  };

  return instance._load({ token });
};

export const searchAccountingPaymentsResults = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'accounting/payments/results',
    method: 'GET',
    ...params,
  });

  instance.convertQueryToServerSchema = body => {
    return {
      ...compose(removeNull, removeEmptyString, createQueryFromApi)(body),
    };
  };

  return instance._load({ token });
};

export const autoGenerateInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/auto',
  method: 'POST',
  ...params,
})._load({ token });

export const getAccountingBookingPayments = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments/booking/{:bookingId}',
  method: 'GET',
  ...params,
})._load({ token });

export const getOutboxInvoicesBase = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/invoice_base/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const sendInvoicesList = (token, params = {}) => new BaseApiClass({
  url: 'accounting/send_invoices',
  method: 'PUT',
  ...params,
})._load({ token });

export const sendAktsList = (token, params = {}) => new BaseApiClass({
  url: 'accounting/send_akts',
  method: 'PUT',
  ...params,
})._load({ token });

export const sendActs = (token, params = {}) => {
  const instance = new BaseApiClass({
    url: 'accounting/akt_generate',
    method: 'POST',
    ...params,
  });

  instance.parseBodyToClientSchema = ({
    recommendation_date: recommendationDate,
    ...rest
  }) => ({ recommendationDate, ...rest });

  return instance._load({ token });
};
export const deleteAkts = (token, params = {}) => new BaseApiClass({
  url: 'accounting/delete_akt',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const createAccountingPayment = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments',
  method: 'POST',
  ...params,
})._load({ token });

export const getAccountingPayment = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const getDefaultAccountingPayment = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments/defaults',
  method: 'GET',
  ...params,
})._load({ token });

export const updateAccountingPayment = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteAccountingPayment = (token, params = {}) => new BaseApiClass({
  url: 'accounting/payments/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getAccountingAccounts = (token, params = {}) => new BaseApiClass({
  url: 'accounting/accounts',
  method: 'GET',
  ...params,
})._load({ token });

export const getAccountingCounterparties = (token, params) => {
  const instance = new BaseApiClass({
    url: 'accounting/counterparties_list',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = data => data.map(({
    is_deleted: isDeleted,
    counterparty_group: counterpartyGroup,
    legal_name: legalName,
    ...rest
  }) => ({ isDeleted, counterpartyGroup, legalName, ...rest }));

  return instance._load({ token });
};

export const getCounterpartiesLink = (token, params = {}) => new BaseApiClass({
  url: 'accounting/counterparties/{:id}/admin_link',
  method: 'GET',
  ...params,
})._load({ token });

export const getAccountingCashFlows = (token, params = {}) => new BaseApiClass({
  url: 'accounting/cash_flows',
  method: 'GET',
  ...params,
})._load({ token });

export const getAccountingOrganizations = (token, params = {}) => new BaseApiClass({
  url: 'accounting/organizations',
  method: 'GET',
  ...params,
})._load({ token });

export const getAccountingContracts = (token, params) => {
  const instance = new BaseApiClass({
    url: 'accounting/counterparties/{:id}/contracts',
    method: 'GET',
    ...params,
  });

  instance.parseBodyToClientSchema = R.map(({ date_stop: dateStop, ...rest }) => ({
    dateStop,
    ...rest
  }));

  return instance._load({ token });
};

export const getAccountingInvoiceBase = (token, params = {}) => new BaseApiClass({
  url: 'accounting/counterparties/{:id}/invoices_base',
  method: 'GET',
  ...params,
})._load({ token });

export const createAccountingInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices',
  method: 'POST',
  ...params,
})._load({ token });

export const updateAccountingInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/{:id}',
  method: 'PUT',
  ...params,
})._load({ token });

export const getAccountingInvoiceDefault = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/defaults',
  method: 'GET',
  ...params,
}).setParseBodyToClientSchema(R.pipe(
  R.over(R.lensProp('amount'), fixedValueAndConvertToNumber),
  R.over(R.lensProp('amount_currency'), fixedValueAndConvertToNumber)
))._load({ token });

export const getAccountingInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const getAkt = (token, params = {}) => new BaseApiClass({
  url: 'accounting/akt/{:id}',
  method: 'GET',
  ...params,
})._load({ token });

export const sendAccountingInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/{:id}/send',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteAccountingInvoice = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const sendAkt = (token, params = {}) => new BaseApiClass({
  url: 'accounting/akt/{:id}/send',
  method: 'PUT',
  ...params,
})._load({ token });

export const deleteAkt = (token, params = {}) => new BaseApiClass({
  url: 'accounting/akt/{:id}',
  method: 'DELETE',
  successCode: 204,
  ...params,
})._load({ token });

export const getAccountingFranchise = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/franchaizing',
  method: 'GET',
  ...params,
})._load({ token });

export const sendAccountingFranchise = (token, params = {}) => new BaseApiClass({
  url: 'accounting/invoices/franchaizing',
  method: 'POST',
  ...params,
})._load({ token });

export const getAccountingToExel = () => new BaseApiClass({
  method: 'GET',
  url: 'accounting/invoices.xlsx',
});
export const getPaymentsToExel = () => new BaseApiClass({
  method: 'GET',
  url: 'accounting/payments.xlsx',
});
export const downloadAkt = (params = {}) => new BaseApiClass({
  method: 'GET',
  url: 'accounting/akt/{:id}.pdf',
  ...params,
});
