import { all, call, delay, fork, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import * as R from 'ramda';
import { operatorsActions } from '@otpusk/apisearch-toolbox/dist/operators';
import { createRateHash } from '@otpusk/apisearch-toolbox/dist/operators/helpers';
import moment from 'moment';

import { registerUI, resetUI, updateUI } from 'bus/ui/actions';
import { createUi } from 'bus/ui/helpers';
import { bookingActions } from 'bus/booking/actions';
import { getPathName } from 'bus/router/selectors';
import { commonActions } from 'bus/common/actions';
import { getBookingData, getBookingDate } from 'bus/booking/selectors';
import { accountingActions } from 'bus/accounting/actions';
import { getToken } from 'bus/auth/selectors';
import { BOOKING_END2END_BOOKING_UI, CALCULATE_END2END_BOOKING } from 'bus/booking/constants';

import book from 'routes/book';

import { getCrmTypes } from 'crms/utils';

import { getBookings } from 'api/methods/booking';
import { getCommissionForCounterparties } from 'api/methods/tf/counterparty';

import { BOOKING_ADMIN_PAGE_UI, BOOKINGS_PAGE_UI, GET_BOOKING_DOCUMENTS_UI, GET_CO_COMMISSIONS, GET_CONNECTED_BOOKINGS_UI, GET_TA_COMMISSIONS, START_END_2_END_BOOKING_UI } from './constants';

import {
  getBookingDocuments,
  getCOCommission,
  getConnectedBooking,
  getTACommission,
  initializeBookingPage,
  initializeBookingsPage,
  mergeConnectedBookings,
  setCOCommission,
  setConnectedBookings,
  setTaCommission,
  startEnd2EndBooking
} from './actions';

const DELAY = 60000;
const BOOKING_VIES_UPDATE_DELAY = 1000 * 60;

function* setGettingMessages(bookingId) {
  while (true) {
    yield put(bookingActions.getBookingMessages(bookingId));
    yield delay(DELAY);
  }
}

function* setBookingView(bookingId) {
  while (true) {
    yield put(bookingActions.getBookingViews(bookingId));
    yield delay(BOOKING_VIES_UPDATE_DELAY);
  }
}

const DATE_FORMAT = 'YYYY-MM-DD';

const createBookingDates = date => {
  const starDay = moment(date);
  const now = moment();

  let dateFrom;

  const diffInMonths = now.diff(starDay, 'months');
  const isStartDateInFuture = starDay.diff(now, 'days') > 0;

  if (isStartDateInFuture) dateFrom = now.format(DATE_FORMAT);
  else {
    dateFrom = diffInMonths < 2
      ? starDay.format(DATE_FORMAT)
      : now.subtract(2, 'month').format(DATE_FORMAT);
  }

  return {
    dateFrom,
    dateTo: moment().format(DATE_FORMAT)
  };
};

function* getRates() {
  const date = yield select(getBookingDate);

  const { is5Stars, isEasybooking } = getCrmTypes();

  const { dateFrom, dateTo } = createBookingDates(date);

  yield put(bookingActions.setRateHash(
    createRateHash(dateFrom, dateTo)
  ));

  yield put(
    operatorsActions.getCurrencyRates(dateFrom, dateTo, is5Stars
      ? { from: 'kzt' }
      : isEasybooking
        ? { from: 'uzs' }
        : {}
    )
  );
}

function* initializeBookingPageSaga() {
  yield put(registerUI(createUi({ loading: true }), BOOKING_ADMIN_PAGE_UI));

  const pathname = yield select(getPathName);

  const { params: { id } } = matchPath(pathname, { path: book.adminBooking.children.booking, exact: true });

  yield fork(setGettingMessages, id);
  yield fork(setBookingView, id);

  yield all([
    put(bookingActions.getBooking(id)),
    put(bookingActions.getMessagesTemplates()),
    put(getBookingDocuments(id)),
    put(accountingActions.getCounterparties())
  ]);

  const [success] = yield race([
    take(bookingActions.getBookingSuccess),
    take(bookingActions.getBookingFail)
  ]);

  if (success) {
    const booking = yield select(getBookingData);

    yield all([
      booking.counterparty_tf && put(accountingActions.getCounterpartiesLink(booking.counterparty_tf.id)),
      !R.isEmpty(booking.connectedBookings ?? []) && put(setConnectedBookings(booking.connectedBookings)),
    ]);

    yield fork(getRates);
  }

  yield put(updateUI({ completed: true, loading: false }, BOOKING_ADMIN_PAGE_UI));
}

function* initializeBookingsPageSaga() {
  yield put(registerUI(createUi({ loading: true }), BOOKINGS_PAGE_UI));

  yield put(commonActions.getManagers());
  yield put(accountingActions.getCounterparties());

  yield put(updateUI({ completed: true, loading: false }, BOOKINGS_PAGE_UI));
}

function* getBookingDocumentsSaga({ payload: id }) {
  yield put(registerUI(createUi({ loading: true }), GET_BOOKING_DOCUMENTS_UI));

  yield all([
    put(bookingActions.getDocuments(id)),
    put(commonActions.getDocumentsTypes(id)),
  ]);

  yield all([
    race([
      take(bookingActions.getDocumentsSuccess),
      take(bookingActions.getDocumentsFail),
    ]),
    race([
      take(commonActions.getDocumentsTypesSuccess),
      take(commonActions.getDocumentsTypesFail),
    ])
  ]);

  yield put(updateUI({ completed: true, loading: false }, GET_BOOKING_DOCUMENTS_UI));
}

function* getConnectedBookingsSaga() {
  const token = yield select(getToken);
  const booking = yield select(getBookingData);

  yield put(registerUI(createUi({ loading: true }), GET_CONNECTED_BOOKINGS_UI));

  try {
    const { bookings } = yield call(getBookings, token, {
      queryParams: {
        operators: booking.operator.id,
        sort: 'date',
        page: 1,
        limit: 100,
        ...booking.counterparty_tf
          ? { counterparties: booking.counterparty_tf.id }
          : {}
      }
    });

    yield put(mergeConnectedBookings(bookings));
  } catch (error) {
    yield put(registerUI(createUi({ error: true, message: error.message }), GET_CONNECTED_BOOKINGS_UI));
  }

  yield put(updateUI({ completed: true, loading: false }, GET_CONNECTED_BOOKINGS_UI));
}

const itemsMapper = R.map(
  R.applySpec({
    text: R.prop('name'),
    value: R.prop('percent'),
  })
);

function* getCommission(counterpartyID, operatorID) {
  const token = yield select(getToken);

  const { items } = yield call(
    getCommissionForCounterparties,
    token,
    {
      pathParams: { id: counterpartyID },
      queryParams: { operator: operatorID }
    });

  return itemsMapper(items);
}

function* getTACommissionSaga({ payload: { counterpartyID, operatorID } }) {
  yield put(registerUI(createUi({ loading: true }), GET_TA_COMMISSIONS));

  try {
    const commission = yield call(getCommission, counterpartyID, operatorID);

    yield put(setTaCommission(commission));
  } catch (error) {
    yield put(registerUI(createUi({ error: true, message: error.message }), GET_TA_COMMISSIONS));
  }
  yield put(updateUI({ completed: true, loading: false }, GET_TA_COMMISSIONS));
}

function* getCOCommissionSaga({ payload: operatorID }) {
  yield put(registerUI(createUi({ loading: true }), GET_CO_COMMISSIONS));

  try {
    const { isTat, isNakanikuly, is5Stars, isEasybooking } = getCrmTypes();

    const counterpartyID = R.cond([
      [R.equals(isTat), R.always(791)],
      [R.equals(isNakanikuly), R.always(5)],
      [R.equals(is5Stars), R.always(4)],
      [R.equals(isEasybooking), R.always(1)],
    ])(true);

    const commission = yield call(getCommission, counterpartyID, operatorID);

    yield put(setCOCommission(commission));
  } catch (error) {
    yield put(registerUI(createUi({ error: true, message: error.message }), GET_CO_COMMISSIONS));
  }
  yield put(updateUI({ completed: true, loading: false }, GET_CO_COMMISSIONS));
}

function* End2EndBookingSaga({ payload: entity }) {
  yield put(registerUI(createUi({ loading: true }), START_END_2_END_BOOKING_UI));

  yield put(
    bookingActions.bookingEnd2EndBooking(entity)
  );

  const [, error] = yield race([
    take(action => {
      if (action.type !== updateUI.toString()) return false;

      return action.payload.path.includes(BOOKING_END2END_BOOKING_UI) && action.payload.nextUI?.success;
    }),
    take(action => {
      if (action.type !== updateUI.toString()) return false;

      return action.payload.path.includes(BOOKING_END2END_BOOKING_UI) && action.payload.nextUI?.error;
    }),
  ]);

  if (error) {
    yield put(resetUI(CALCULATE_END2END_BOOKING));
  }

  yield put(updateUI({ completed: true, loading: false }, START_END_2_END_BOOKING_UI));
}

export default function* bookingAdminPageRootSaga() {
  yield takeEvery(initializeBookingPage, initializeBookingPageSaga);
  yield takeEvery(initializeBookingsPage, initializeBookingsPageSaga);
  yield takeEvery(getBookingDocuments, getBookingDocumentsSaga);
  yield takeEvery(getConnectedBooking, getConnectedBookingsSaga);
  yield takeEvery(getTACommission, getTACommissionSaga);
  yield takeEvery(getCOCommission, getCOCommissionSaga);
  yield takeEvery(startEnd2EndBooking, End2EndBookingSaga);
}
