import { bookingActions as actions } from 'bus/booking/actions';

export const bookingSchedulesReducer = {
  [actions.getBookingSchedulesSuccess]: (state, { payload: schedules }) => {
    return state.setIn(['booking', 'schedules'], schedules);
  },
  [actions.createBookingScheduleSuccess]: (state, { payload: { schedule, id } }) => {
    if (!id) {
      return state.updateIn(['booking', 'schedules'], schedules => [...schedules, schedule]);
    }

    const scheduleIdx = state
      .getIn(['booking', 'schedules'])
      .findIndex(item => id === item.id);

    return state.setIn(['booking', 'schedules', scheduleIdx], schedule);
  },
  [actions.updateBookingScheduleSuccess]: (state, { payload: schedule }) => {
    const scheduleIdx = state
      .getIn(['booking', 'schedules'])
      .findIndex(({ id }) => id === schedule.id);

    return state.setIn(['booking', 'schedules', scheduleIdx], schedule);
  },
  [actions.deleteBookingScheduleSuccess]: (state, { payload: scheduledId }) => {
    return state.updateIn(
      ['booking', 'schedules'],
      payments => payments.filter(({ id }) => id !== scheduledId)
    );
  },
};
