import { select, put, call } from 'redux-saga/effects';
import { get } from 'immutable';

// instruments
import { getDetails, createDetail, deleteDetail, updateDetail, uploadDetailStamp, deleteDetailStamp } from 'api/methods/common/details';
import { uiActions } from 'bus/ui/actions';
import { commonActions } from 'bus/common/actions';

export function* getDetailsWorker() {
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, completed: false, message: '' },
    path: ['common', 'details'],
  }));

  try {
    const { details, fieldset } = yield call(getDetails, token, { pathParams: { tf } });

    yield put(commonActions.getDetailsSuccess(details, fieldset));
  } catch (error) {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'details', 'error'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: 'Ошибка загрузки данных',
      path: ['common', 'details', 'message'],
    }));
    yield put(commonActions.getDetailsFail(error));
  } finally {
    yield put(uiActions.changeUiLoaderFlag({
      status: true,
      path: ['common', 'details', 'completed'],
    }));
    yield put(uiActions.changeUiLoaderFlag({
      status: false,
      path: ['common', 'details', 'loading'],
    }));
  }
}

export function* createDetailWorker({ payload }) {
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));
  const { tempID, detail, options = {} } = payload;

  options.UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: '' },
      path: ['common', 'createDetail', tempID],
    }))
  );

  try {
    const newDetail = yield call(createDetail, token, { bodyParams: detail, pathParams: { tf } });

    yield put(commonActions.createDetailSuccess(tempID, newDetail));
  } catch (error) {
    yield put(commonActions.createDetailFail(error));
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'createDetail', tempID, 'error'],
      }))
    );
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: 'Ошибка при создании реквизита',
        path: ['common', 'createDetail', tempID, 'message'],
      }))
    );
  } finally {
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: false,
        path: ['common', 'createDetail', tempID, 'loading'],
      }))
    );
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'createDetail', tempID, 'completed'],
      }))
    );
  }
}

export function* deleteDetailWorker({ payload }) {
  const { id, options = {} } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  options.UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: null },
      path: ['common', 'deleteDetail', id],
    }))
  );

  try {
    yield call(deleteDetail, token, { pathParams: { tf, id } });
    yield put(commonActions.deleteDetailSuccess(id));
  } catch (error) {
    const { status } = error.msg;

    let message = null;

    switch (status) {
      case 403: {
        message = 'Пользователь не имеет права удалять эти реквизиты';
        break;
      }
      default: message = 'Ошибка при удаление реквизита';
    }

    if (status !== 404) {
      options.UI && (
        yield put(uiActions.changeUiLoaderFlag({
          status: true,
          path: ['common', 'deleteDetail', id, 'error'],
        }))
      );
      options.UI && (
        yield put(uiActions.changeUiLoaderFlag({
          status: message,
          path: ['common', 'deleteDetail', id, 'message'],
        }))
      );

      yield put(commonActions.deleteDetailFail(error));
    } else {
      yield put(commonActions.deleteDetailSuccess(id));
    }
  } finally {
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: false,
        path: ['common', 'deleteDetail', id, 'loading'],
      }))
    );
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'deleteDetail', id, 'completed'],
      }))
    );
  }
}

export function* updateDetailWorker({ payload }) {
  const { id, detail, options = {} } = payload;
  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  options.UI && (
    yield put(uiActions.changeUiLoaderFlag({
      status: { loading: true, error: false, completed: false, message: null },
      path: ['common', 'updateDetail', id],
    }))
  );

  try {
    const newDetail = yield call(
      updateDetail,
      token,
      { pathParams: { id, tf }, bodyParams: detail }
    );

    yield put(commonActions.updateDetailSuccess(newDetail.id, newDetail));
  } catch (error) {
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'updateDetail', id, 'error'],
      }))
    );
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: 'Ошибка при редактирование реквизита',
        path: ['common', 'updateDetail', id, 'message'],
      }))
    );
    yield put(commonActions.updateDetailFail(error));
  } finally {
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: false,
        path: ['common', 'updateDetail', id, 'loading'],
      }))
    );
    options.UI && (
      yield put(uiActions.changeUiLoaderFlag({
        status: true,
        path: ['common', 'updateDetail', id, 'completed'],
      }))
    );
  }
}

export function* uploadDetailStampWorker({ payload }) {
  const { id, file } = payload;

  const token = yield select(({ auth }) => auth.get('token'));
  const tf = yield select(({ auth }) => auth.getIn(['profile', 'tf', 'id']));

  const bodyParams = new FormData();

  bodyParams.append('file', file);

  try {
    const { stamp_image_patch: url } = yield call(
      uploadDetailStamp,
      token,
      { pathParams: { id, tf }, bodyParams }
    );

    yield put(commonActions.uploadDetailStampSuccess(id, url));
  } catch (error) {
    yield put(commonActions.uploadDetailStampFail(error));
  }
}

export function* deleteDetailStampWorker({ payload }) {
  const { id, clearFormik, workerOptions } = payload;
  const UI = get(workerOptions, 'UI', false);

  const [token, tf] = yield select(({ auth }) => [
    auth.get('token'),
    auth.getIn(['profile', 'tf', 'id'])
  ]);

  UI && (yield put(uiActions.changeUiLoaderFlag({
    status: { loading: true, error: false, message: null, completed: false },
    path: ['common', 'deleteDetailStamp', id],
  })));

  try {
    yield call(deleteDetailStamp, token, { pathParams: { id, tf } });

    yield put(commonActions.deleteDetailStampSuccess(id));
    yield call(clearFormik);
  } catch (error) {
    const { status } = error.msg || {};

    let message = null;

    switch (status) {
      case 400: {
        message = 'Невалидный запрос';

        break;
      }
      case 403: {
        message = 'Отказано в доступе. Отсутствует доступ к загрузкам данной турфирмы';

        break;
      }
      case 404: {
        message = 'Турфирма не найдена';

        break;
      }
      default: message = 'Ошибка сервера';
    }

    yield put(commonActions.deleteDetailStampFail());
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'deleteDetailStamp', id, 'error'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: message, path: ['common', 'deleteDetailStamp', id, 'message'] })));
  } finally {
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: false, path: ['common', 'deleteDetailStamp', id, 'loading'] })));
    UI && (yield put(uiActions.changeUiLoaderFlag({ status: true, path: ['common', 'deleteDetailStamp', id, 'completed'] })));
  }
}
