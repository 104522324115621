import { takeEvery } from 'redux-saga/effects';

// insturments
import { accountingActions as actions } from '../actions';

// workers
import { searchPaymentsWorker } from './workers/searchPayments';
import { getPaymentWorker } from './workers/getPayment';
import { getDefaultPaymentWorker } from './workers/getDefaultPayment';
import { createPaymentWorker } from './workers/createPayment';
import { updatePaymentWorker } from './workers/updatePayment';
import { getAccountsWorker } from './workers/getAccounts';
import { getCounterpartiesWorker } from './workers/getCounterparties';
import getCounterpartiesLinkWorker from './workers/getCounterpartiesLink';
import { getCashFlowsWorker } from './workers/getCashFlows';
import { getOrganizationsWorker } from './workers/getOrganizations';
import { getContractsWorker } from './workers/getContracts';

// invoices
import {
  searchInvoicesWorker,
  createInvoiceWorker,
  deleteInvoiceWorker,
  sendInvoiceWorker,
  getInvoiceBaseWorker,
  getInvoiceDefaultWorker,
  getInvoiceTemplateWorker,
  getFranchiseInvoicesWorker,
  sendFranchiseInvoicesWorker,
  updateInvoiceWorker,
  reExposeInvoiceWorker
} from './workers/invoices';

import getOutboxInvoiceBaseWorker from './workers/invoices/getOutboxInvoiceBase';
import { searchAktWorker } from './workers/searchAkt';
import { deleteAktWorker } from './workers/deleteAkt.js';
import getAktWorker from './workers/getAct.js';
import { sendAktWorker } from './workers/sendAkt.js';
import { sendActsSagaWithUI } from './workers/sendAkts';

export const accountingWatchers = Object.freeze({
  * searchPaymentsWatcher() {
    yield takeEvery([
      actions.search,
      actions.createPaymentSuccess,
      actions.updatePaymentSuccess
    ], searchPaymentsWorker);
  },
  * getPaymentWatcher() {
    yield takeEvery(actions.getPayment, getPaymentWorker);
  },
  * getDefaultPaymentWatcher() {
    yield takeEvery(actions.getDefaultPayment, getDefaultPaymentWorker);
  },
  * createPaymentWatcher() {
    yield takeEvery(actions.createPayment, createPaymentWorker);
  },
  * updatePaymentWatcher() {
    yield takeEvery(actions.updatePayment, updatePaymentWorker);
  },
  * getAccountsWatcher() {
    yield takeEvery(actions.getAccounts, getAccountsWorker);
  },
  * getCounterpartiesWatcher() {
    yield takeEvery(actions.getCounterparties, getCounterpartiesWorker);
  },
  * getCounterpartiesLinkWatcher() {
    yield takeEvery(actions.getCounterpartiesLink, getCounterpartiesLinkWorker);
  },
  * getCashFlowsWatcher() {
    yield takeEvery(actions.getCashFlows, getCashFlowsWorker);
  },
  * getOrganizationsWatcher() {
    yield takeEvery(actions.getOrganizations, getOrganizationsWorker);
  },
  * getContractsWatcher() {
    yield takeEvery(actions.getContracts, getContractsWorker);
  },

  // invoices
  * searchInvoicesWatcher() {
    yield takeEvery([
      actions.searchInvoices,
      actions.createInvoiceSuccess,
      actions.createInvoicePaymentSuccess,
      actions.updateInvoiceSuccess
    ], searchInvoicesWorker);
  },
  * createInvoiceWatcher() {
    yield takeEvery(actions.createInvoice, createInvoiceWorker);
  },
  * reExposeInvoiceWatcher() {
    yield takeEvery(actions.reExposeInvoice, reExposeInvoiceWorker);
  },
  * searchAktWatcher() {
    yield takeEvery(actions.searchAkt, searchAktWorker);
  },
  * updateInvoiceWatcher() {
    yield takeEvery(actions.updateInvoice, updateInvoiceWorker);
  },
  * deleteInvoiceWatcher() {
    yield takeEvery(actions.deleteInvoice, deleteInvoiceWorker);
  },
  * deleteAktWatcher() {
    yield takeEvery(actions.deleteAkt, deleteAktWorker);
  },
  * getAktWatcher() {
    yield takeEvery(actions.getAkt, getAktWorker);
  },
  * sendAktWatcher() {
    yield takeEvery(actions.sendAkt, sendAktWorker);
  },
  * sendAktSWatcher() {
    yield takeEvery(actions.sendAkts, sendActsSagaWithUI);
  },
  * sendInvoiceWatcher() {
    yield takeEvery(actions.sendInvoice, sendInvoiceWorker);
  },
  * getInvoiceDefaultWatcher() {
    yield takeEvery(actions.getInvoiceDefault, getInvoiceDefaultWorker);
  },
  * getInvoiceBaseWatcher() {
    yield takeEvery(actions.getInvoiceBase, getInvoiceBaseWorker);
  },
  * getInvoiceTemplateWatcher() {
    yield takeEvery(actions.getInvoiceTemplate, getInvoiceTemplateWorker);
  },
  * getFranchiseInvoicesWatcher() {
    yield takeEvery(actions.getFranchiseInvoices, getFranchiseInvoicesWorker);
  },
  * sendFranchiseInvoicesWatcher() {
    yield takeEvery(actions.sendFranchiseInvoices, sendFranchiseInvoicesWorker);
  },
  * getOutboxInvoiceBaseWatcher() {
    yield takeEvery(actions.getOutboxInvoiceBase, getOutboxInvoiceBaseWorker);
  },
});
