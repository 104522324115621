import { Map } from 'immutable';
import { handleActions, combineActions } from 'redux-actions';

// instruments
import { accountingActions as actions } from './actions';

const initialState = Map({
  search: {
    data: {
      results: [],
      total: null,
      limit: 50,
    },
    page: 1,
    query: null,
    totalResults: null,
  },
  payment: { data: null, },
  invoice: { data: null, },
  invoiceTemplate: {
    data: null,
    modal: { open: false, values: {} },
  },
  invoiceSendingModal: {
    data: {},
  },
  franchise: {
    data: null,
    open: false,
  },
  accounts: [],
  counterparties: [],
  counterpartiesLink: null,
  cashFlows: [],
  organizations: [],
  contracts: [],
  invoiceBase: [],
  outboxInvoiceBase: [],
  aktTemplate: null,
});

export default handleActions({
  // common search for payments and invoices
  [actions.searchSuccess]: (state, { payload: { results, total, limit } }) => state.setIn(['search', 'data'], { results, total, limit }),
  [actions.setQuery]: (state, { payload: query }) => state.setIn(['search', 'query'], query),
  [actions.setPage]: (state, { payload: page }) => state.setIn(['search', 'page'], page),
  [actions.setLimit]: (state, { payload: limit }) => state.setIn(['search', 'data', 'limit'], limit),
  [actions.setOrder]: (state, { payload: order }) => state.setIn(['search', 'order'], order),
  [actions.setSort]: (state, { payload: order }) => state.setIn(['search', 'sort'], order),

  // payments
  [actions.getPaymentsResultsSuccess]: (state, { payload: totalResults }) => {
    return state.setIn(['search', 'totalResults'], totalResults);
  },

  [combineActions(
    actions.getDefaultPaymentSuccess,
    actions.getPaymentSuccess,
    actions.clearPayment
  )]: (state, { payload: payment }) => state.setIn(['payment', 'data'], payment),

  [actions.mergePayment]: (state, { payload: payment }) => state.mergeIn(['payment', 'data'], payment),
  [actions.getAccountsSuccess]: (state, { payload: accounts }) => state.set('accounts', accounts),
  [actions.getCounterpartiesSuccess]: (state, { payload }) => state.set('counterparties', payload),
  [actions.getCounterpartiesLinkSuccess]: (state, { payload }) => state.set('counterpartiesLink', payload),
  [actions.getCashFlowsSuccess]: (state, { payload }) => state.set('cashFlows', payload),
  [actions.getOrganizationsSuccess]: (state, { payload }) => state.set('organizations', payload),

  [combineActions(actions.getContractsSuccess, actions.clearContracts)]: (state, { payload }) => state.set('contracts', payload),

  // invoices
  [combineActions(actions.getInvoiceBaseSuccess, actions.clearInvoiceBase)]: (state, { payload }) => state.set('invoiceBase', payload),

  [actions.getInvoiceDefaultSuccess]: (state, { payload: invoice }) => state.setIn(['invoice', 'data'], invoice),

  [combineActions(
    actions.getInvoiceTemplateSuccess,
    actions.clearInvoiceTemplate
  )]: (state, { payload: invoice }) => state.setIn(['invoiceTemplate', 'data'], invoice),
  [actions.switchInvoiceTemplateModal]: (state, { payload: bool }) => state.setIn(['invoiceTemplate', 'modal', 'open'], bool),
  [actions.setInvoiceTemplateModalValues]: (state, { payload: values }) => state.setIn(['invoiceTemplate', 'modal', 'values'], values),
  [actions.setInvoiceSendingModalValues]: (state, { payload: values }) => state.setIn(['invoiceSendingModal', 'data'], values),
  [actions.resetInvoiceSendingModalValues]: state => state.setIn(['invoiceSendingModal', 'data'], {}),
  [actions.setInvoiceTemplateModalValues]: (state, { payload: values }) => state.setIn(['invoiceTemplate', 'modal', 'values'], values),

  [actions.deleteInvoiceSuccess]: (state, { payload: id }) => {
    const items = state.getIn(['search', 'data', 'results']).filter(item => item.id !== id);

    return state.setIn(['search', 'data', 'results'], items);
  },
  [actions.deleteAktSuccess]: (state, { payload: list }) => {
    const items = state.getIn(['search', 'data', 'results']).filter(item => !list.includes(item.id));

    return state.setIn(['search', 'data', 'results'], items);
  },
  [actions.getAktSuccess]: (state, { payload }) => state.set('aktTemplate', payload),
  [actions.resetAkt]: state => state.set('aktTemplate', null),
  [combineActions(
    actions.getOutboxInvoiceBaseSuccess,
    actions.clearOutboxInvoiceBase
  )]: (state, { payload }) => state.set('outboxInvoiceBase', payload),

  // franchise
  [combineActions(
    actions.getFranchiseInvoicesSuccess,
    actions.sendFranchiseInvoicesSuccess,
    actions.clearFranchiseInvoices
  )]: (state, { payload: data }) => state.setIn(['franchise', 'data'], data),
  [actions.switchFranchiseModal]: (state, { payload: bool }) => state.setIn(['franchise', 'open'], bool),

}, initialState);
