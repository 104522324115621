import * as R from 'ramda';

import { CZK_CURRENCY, EUR_CURRENCY, KZT_CURRENCY, PLN_CURRENCY, UAH_CURRENCY_OTPUSK, USD_CURRENCY, USZ_CURRENCY } from 'helpers/currency';

import { LOCALES } from 'localization/constants';

import { CRM_TYPES } from './constants';

export const getDefaultLangForCrm = () => R.cond([
  [R.equals(CRM_TYPES['5stars']), R.always(LOCALES.RU)],
  [R.equals(CRM_TYPES.easybooking), R.always(LOCALES.RU)],
  [R.T, R.always(LOCALES.UA)]
])(__CRM__);

export const getDefaultCurrencyForCrm = () => R.cond([
  [R.equals(CRM_TYPES['5stars']), R.always(KZT_CURRENCY)],
  [R.equals(CRM_TYPES.easybooking), R.always(USZ_CURRENCY)],
  [R.T, R.always(UAH_CURRENCY_OTPUSK)]
])(__CRM__);

export const getAllowedCurrencyForCrm = () => R.cond([
  [R.equals(CRM_TYPES['5stars']), R.always([KZT_CURRENCY, USD_CURRENCY, EUR_CURRENCY, PLN_CURRENCY])],
  [R.equals(CRM_TYPES.easybooking), R.always([USZ_CURRENCY, USD_CURRENCY, EUR_CURRENCY])],
  [R.T, R.always([UAH_CURRENCY_OTPUSK, USD_CURRENCY, EUR_CURRENCY, PLN_CURRENCY, CZK_CURRENCY])]
])(__CRM__);

export const getFavIcon = () => {
  switch (__CRM__) {
    case CRM_TYPES.mt: return 'default_favicon.png';
    case CRM_TYPES.tat: return 'tat_favicon.png';
    case CRM_TYPES['5stars']: return '5stars_favicon.png';
    case CRM_TYPES.easybooking: return 'EB_favicon.png';
    case CRM_TYPES.nakanikuly: return 'nk_favicon.png';
    default: return 'default_favicon.png';
  }
};

export const getCountryCodeForCrm = () => R.cond([
  [R.equals(CRM_TYPES['5stars']), R.always('kz')],
  [R.equals(CRM_TYPES.easybooking), R.always('uz')],
  [R.T, R.always('ua')]
])(__CRM__);

export const getCRMTitle = () => R.cond([
  [R.equals(CRM_TYPES.tat), R.always('CRM.TAT.ua')],
  [R.equals(CRM_TYPES['5stars']), R.always('5stars')],
  [R.equals(CRM_TYPES.nakanikuly), R.always('НаКанікули')],
  [R.T, R.always('МоїТуристи')]
])(__CRM__);

export const getTourscannerThemeColor = () => R.cond([
  [R.equals(CRM_TYPES.tat), R.always('#2996d0')],
  [R.equals(CRM_TYPES['5stars']), R.always('#0033B1')],
  [R.equals(CRM_TYPES.easybooking), R.always('#00B8D7')],
  [R.equals(CRM_TYPES.nakanikuly), R.always('#00b4b4')],
  [R.T, R.always('#8c4a91')]
])(__CRM__);
