import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import classnames from 'classnames';

import NavPointIcon from 'assets/nav-point.svg';
import NavPointIconMobile from 'assets/nav-point-mobile.svg';
import MoreIcon from 'assets/icons/materialIcons/more_20.svg';

import { bookingActions } from 'bus/booking/actions.js';
import { claimActions } from 'bus/claim/actions';

import { subscriptionAccess, useSubscription } from 'containers/molecules/subscription';
import { getCrmTypes } from 'crms/utils';

import { useLocation } from 'react-router';
import { Item } from './Item';

import { getPagesForCRM } from './constants';

import Styles from './styles.scss';

const isMobile = matchMedia('(max-width: 767px)').matches;

const RenderItem = ({ text, isNeedCompareSearchPath, activeSearchStr, ...page }) => {
  const { search } = useLocation();

  const activeClassName = isNeedCompareSearchPath ? activeSearchStr === search : true;

  return (
    <Item
      activeClassName={activeClassName ? Styles.active : null}
      content={text}
      key={text}
      {...page}
    />
  );
};

const { mainPages, additionPages } = getPagesForCRM();

const Menu = ({ onClick }) => {
  const isHaveSubscriptioByCRM = useSubscription({ checkAccess: subscriptionAccess });

  const { isMt } = getCrmTypes();

  const dispatch = useDispatch();

  useEffect(() => {
    isHaveSubscriptioByCRM && dispatch(claimActions.startAutoFetchCountClaims());
    isHaveSubscriptioByCRM && !isMt && dispatch(bookingActions.getMessagesCountUnread());

    return () => isHaveSubscriptioByCRM && dispatch(claimActions.stopAutoFetchCountClaims());
  }, [isHaveSubscriptioByCRM]);

  return (
    <div className={Styles.root} onClick={onClick}>
      {R.map(RenderItem, mainPages)}
      {additionPages && (
        <div
          className={classnames(
            Styles.additionButton,
            Styles.purple,
            Styles.navItem
          )}
        >
          <div
            className={Styles.moreIcon}
            dangerouslySetInnerHTML={{ __html: MoreIcon }}
          />
          <div
            className={classnames(Styles.navPoint, 'violet')}
            dangerouslySetInnerHTML={{ __html: isMobile ? NavPointIconMobile : NavPointIcon }}
          />
          <div className={Styles.additionPages}>
            {R.map(RenderItem, additionPages)}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Menu);
